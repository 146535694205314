:root {
  --primary-color: #c29432;
  --strong-orange-color: #ff6f00;
  --secondary-color: #32a1aa;
  --mauve-color: #75788d;
  --accent-color: #e74c3c;
  --background-color: #eeeeee;
  --text-color: #2c3e50;
  --gray-color: #77838f;
  --gray-background: #eeeeee;
  --green-color: #50bb4c;
  --gray-light: #dee8f7;
}
/* Example of dark theme */
.dark-theme {
  --primary-color: #1abc9c;
  --secondary-color: #16a085;
  --accent-color: #c0392b;
  --background-color: #34495e;
  --text-color: #ecf0f1;
}

.main-color {
  color: var(--primary-color) !important;
}
.main-background-color {
  background-color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--secondary-color) !important;
}
.secondary-background-color {
  background-color: var(--secondary-color) !important;
}
.strong-orange-color {
  color: var(--strong-orange-color) !important;
}
.strong-orange-background-color {
  background-color: var(--strong-orange-color) !important;
}
.strong-orange-border-color {
  border-color: var(--strong-orange-color) !important;
}
.mauve-background-color {
  background-color: var(--mauve-color) !important;
}
.secondary-border-color {
  border-color: var(--secondary-color) !important;
}
.gray-background-color {
  background-color: var(--gray-background) !important;
}
.gray-color {
  color: var(--gray-color) !important;
}
.green-background-color {
  background-color: var(--green-color) !important;
}
.gray-light-color{
  background-color: var(--gray-light) !important;
}