/* You can add global styles to this file, and also import other style files */

@import "scss/variables";

@import "app/theme/themes";
@import './theme.scss';
/* fonts-icon */

@import "scss/fonts/fontawesome/scss/fontawesome";
@import "scss/fonts/feather/iconfont";

@import "scss/general";
@import "scss/generic";
@import "scss/mixins/function";

/* important element */
@import "scss/menu/menu-lite";

/* basic elements */
@import "scss/theme-elements/theme-elements";

/* third party modules style*/
@import "scss/plugins/plugins";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "scss/custom";
@import "../node_modules/intl-tel-input/build/css/intlTelInput.css";

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: 'DM-sans';
//   src: url('assets/fonts/dm-sans/DMSans-Regular-BF64376d323f095.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: DM-Sans;
  src: url(assets/fonts/dm-sans/DMSans-Regular-BF64376d323f095.ttf);
}

@font-face {
  font-family: Tajawal;
  src: url(assets/fonts/tajawal_5.0.13/ttf/tajawal-arabic-400-normal.ttf) format('opentype');
}

.rtl{
  direction: rtl;
  text-align: right;
}

.rtl .dropdown-menu {
  right: 0; /* Position dropdown to the right */
  left: auto; /* Ensure left is not set */
}

.rtl .dropdown-item {
  text-align: right; /* Align dropdown items to the right */
}

:root {
  --primaryColor: #56c0a9;
  --primaryColor2: #535D6EFF;
  --sportsSecondary: #144974;
  --sportsBlue: #0096ff;
  --sportsGreen: #198754;
  --sportsRed: rgba(220, 53, 69, 0.63);
  --sportsGray: #6c757d;
  --sportsPurple: #712CF9;
  --systemGray11: #1C1C1EFF;
  --systemGray10: #2C2C2EFF;
  --systemGray9: #3A3A3CFF;
  --systemGray8: #48484AFF;
  --systemGray7: #636366FF;
  --systemGray6: #8E8E93FF;
  --systemGray5: #AEAEB2FF;
  --systemGray4: #C7C7CCFF;
  --systemGray3: #D1D1D6FF;
  --systemGray2: #E5E5EAFF;
  --systemGray1: #F2F2F7FF;

}

.icon-success {
  color: var(--primaryColor);
  font-size: 20px;
}

.icon-danger {
  color: var(--sportsRed);
  font-size: 20px;

}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
}

.bg-gradient-secondary {
  background-image: linear-gradient(310deg, #627594, #a8b8d8);
}

.bg-gradient-info {
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, #17ad37, #98ec2d);
}

.bg-gradient-warning {
  background-image: linear-gradient(310deg, #f53939, #fbcf33);
}

.bg-gradient-dark {
  background-image: linear-gradient(310deg, #141727, #3a416f);
}

.bg-gradient-light {
  background-image: linear-gradient(310deg, #a4508b, #5f0a87);
  background-color: #f6f7fb;
}

.bg-gradient-danger {
  background-image: linear-gradient(310deg, #a64248, #DC3545);
  background-color: #f6f7fb;
}

//LAYOUT

//Header

nb-layout-header nav {
  height: fit-content;
  box-shadow: none;
}

nb-layout-header.fixed,
nav.fixed,
nb-layout-header nav {
  padding: 0;
}

nb-layout-header.fixed {
  z-index: 1000 !important;
}

.mat-toolbar {
  background-color: var(--sportsSecondary);
  color: var(--systemGray11);
  font-size: 1em;
}

.mat-toolbar-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nb-layout-header nav a {
  border-radius: unset;
}

nb-layout-header nav a:hover {
  background-color: transparent;
  border-radius: unset;
}

nb-user .user-name {
  background-color: var(--systemGray7);
  padding: 0.3em 1em;
  border-radius: 4px;
  border: none;
  color: var(--systemGray1);
}

nb-user .user-picture {
  background-color: #000;
  padding: 0.3em 0.5em;
  border: 3px solid var(--systemGray6);
  color: #fff;
}

nb-user.size-medium .user-picture {
  height: 35px;
  width: 35px;
}

nb-sidebar.expanded {
  width: 300px;
}

nb-sidebar.end {
  border-radius: 4px;
  margin-bottom: 1px;
}

// Main

nb-layout-header.fixed ~ .layout-container {
  padding: 60px 8px 10px 6px;
  gap: 0.5em;
  background-color: var(--sportsSecondary);
}

nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 0 0.1em;
}

// Sidebar

nb-layout .layout .layout-container nb-sidebar.fixed,
nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
  top: 60px;
}

nb-sidebar .main-container {
  width: 300px;
}

nb-sidebar .scrollable {
  padding: 2px 4px 0;
  overflow-y: hidden !important;
}

.own-scroll {
  padding: 0 6px 0;
  margin: 0;
}

// PAGES

.page-wrapper {
  margin-bottom: 0;
  margin-right: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0.9em;
    background-color: #fff;

    h2 {
      margin-bottom: 0;
    }

    .buttons {
      width: max-content;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1em;

      .mdc-icon-button {
        font-size: 16px !important;
        padding: 3px !important;
      }
    }
  }

  .page-body {
    width: 100%;
    padding: 0;

    .table-wrapper {
      height: calc(100vh - 158px);
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      padding: 0;
    }
  }

  .page-body.details {
    padding: 0;

    .table-wrapper {
      height: calc(100vh - 112px);
    }
  }

  .page-footer {
    background-color: white;
    border-top: 2px solid var(--primaryColor);
    height: 65px;
    padding: 0 1em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

}

// BUTTONS

button {
  border: none;

  &:focus-visible {
    outline: none;
  }
}

button:hover {
  opacity: 0.96;
}

.primary {
  background: var(--primaryColor);
}

.primary,
.secondary,
.blue,
.black {
  border-radius: 4px;
  font-size: 12px;
  min-width: 108px;
  padding: 0.5em 1em;
  color: var(--systemGray1);
}

.black {
  background: var(--systemGray11);
}

.blue {
  background: var(--primaryColor);
}

.secondary {
  background-color: var(--systemGray7);
}

button:active {
  transform: scale(0.99);
}

a.active {
  background-color: unset;
}

button[disabled] {
  background-color: #939393;
}

//TABLES
table {
  width: 100%;
}

table.mat-mdc-table {

  width: 100%;

  tr.table-header {
    font-weight: bold;
    background-color: var(--sportsSecondary);
    height: 30px;
    color: white;
    border-bottom: 1px solid var(--primaryColor);
  }

  .mdc-data-table__row {
    height: 50px;
  }

  td.mat-mdc-cell.mdc-data-table__cell.cdk-cell.cdk-column-cover_image.mat-column-cover_image.ng-star-inserted > img {
    height: 40px !important;
    width: 40px !important;
  }

  .mdc-data-table__row:hover {
    background-color: var(--systemGray1);
  }

  .mat-mdc-header-cell {
    font-size: 13px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    border-bottom: 2px solid var(--primaryColor)
  }

  .mdc-data-table__cell {
    color: #000;
    font-size: 12px;
    text-align: center;
  }

  .mat-column-id {
    width: 80px;
  }

  .mat-mdc-header-cell-cell:last-of-type,
  .mdc-data-table__cell:last-of-type {
    padding-right: 1em;
  }

  .mdc-icon-button {
    width: 25px;
    height: 25px;
    line-height: 0;
    padding: 0;
  }

  .mdc-icon-button i,
  .mdc-icon-button .mat-icon {
    line-height: 14px;
    font-size: 14px;
  }

  strong {
    font-weight: normal;
  }

  span.link:hover, strong:hover {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  .hoverRequest:hover {
    .mdc-data-table__cell {
      background-color: var(--primaryColor);
      color: #fff;
    }
  }

}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 0 !important;
  width: 0 !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 25px;
  height: 25px;
  line-height: 0;
  padding: 0;
}

.mdc-icon-button i {
  line-height: 14px;
  font-size: 14px;
}

.mdc-icon-button .mat-icon {
  line-height: 20px;
  font-size: 20px;
}

// Trip tables
.content-box {
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  text-align: left;
}

.mat-column-trip_no,
.mat-column-eta,
.mat-column-etd_date,
td.mat-column-trip_no,
td.mat-column-eta,
td.mat-column-etd_date {
  width: 75px;

  .content-box {
    width: 75px;
  }
}

.mat-column-status,
.mat-column-purpose,
td.mat-column-status,
td.mat-column-purpose, {
  width: 68px;

  .content-box {
    width: 68px;
  }
}

.mat-column-origin,
.mat-column-destination,
.mat-column-aircraft,
td.mat-column-origin,
td.mat-column-destination,
td.mat-column-aircraft {
  width: 85px;

  .content-box {
    width: 75px;
  }
}

.content-box::-webkit-scrollbar {
  height: 3px;
}


.mat-column-operator,
.mat-column-supplier,
td.mat-column-supplier,
td.mat-column-operator {
  width: 170px;

  .content-box {
    width: 170px;
  }
}

.mat-column-etd_time,
td.mat-column-etd_time {
  width: 60px;

  .content-box {
    width: 60px;
  }
}

.mat-column-crew-pax,
td.mat-column-crew-pax {
  width: 66px;

  .content-box {
    width: 55px;
  }
}

.window-section-width {
  width: 75vw !important;
  height: 75vh !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px dashed var(--primaryColor);

  nb-card, nb-card-body {
    overflow: visible !important;

  }
}


.window-media-width {
  width: 75%;

  nb-card, nb-card-body {
    overflow: visible !important;

  }
}


//PAGINATOR

.app-paginator {

  .mat-mdc-paginator {
    background-color: transparent;
  }

  .mat-mdc-paginator-container {
    align-items: center;
    min-height: 30px;
  }

  .mat-mdc-paginator-outer-container {
    height: 30px;
    align-items: center;
    min-height: 30px;
    padding-top: 0;
  }

  .mat-mdc-paginator-page-size-select {
    height: 25px;

    .mat-mdc-text-field-wrapper {
      height: 100%;
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0;
    line-height: 0;
  }

  .mat-mdc-paginator-page-size {
    height: 25px;
    align-items: center !important;
  }

  .mat-mdc-form-field-infix {
    min-height: unset;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mdc-icon-button {
    width: 22px;
    height: 22px;
    line-height: 1em;
    background-color: var(--primaryColor);
    cursor: pointer;
    border-radius: 4px;
  }

  .mat-mdc-paginator-icon {
    width: 16px !important;
    fill: #fff !important;
  }

  .mat-mdc-paginator-range-actions {
    gap: 1em;
  }

  .mat-mdc-paginator-range-label,
  .mat-mdc-paginator-page-size-label {
    color: var(--systemGray9);
  }

  .mat-mdc-select-arrow {
    color: var(--primaryColor);
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0;
    line-height: 0;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-left: 0.5em;
  }

  .mat-mdc-paginator-page-size-select {
    height: 25px;

    .mat-mdc-text-field-wrapper {
      height: 100%;
    }
  }
}

.page-footer {
  .mat-mdc-paginator-container {
    background-color: transparent;
  }
}


//SCROLL BAR

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  overflow-y: auto !important;;
  background: #FFEFD7;
  border-radius: 4px;

}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor);;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--systemGray4);
}

::-webkit-scrollbar-track {
  background: transparent;
}

//POP UP WINDOW


@media only screen and (max-width: 767px) {
  .small-window,
  .custom-window {
    width: 400px;
  }
}


.small-window,
.custom-window {
  background-color: white;
  border: 3px solid var(--primaryColor) !important;
  border-radius: 6px;

  nb-card {
    border: none;
    border-radius: 4px;
  }

  nb-card-body.warning {
    background-color: #ff3d71;
  }

  nb-card-header {
    height: 30px;
    background-color: var(--sportsSecondary);
    padding: 0 0 0 1.2em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .buttons {
      gap: 0;

      button {
        background-color: var(--sportsSecondary);
        flex: 0 !important;
      }

      nb-icon {
        width: 13px;
        height: 13px;
      }

      button:first-child {
        nb-icon {
          background-color: #FE9503;
          border-radius: 50%;
        }

        nb-icon svg {
          fill: #FE9503;
        }
      }

      button:last-child {
        nb-icon {
          width: 24px;
          height: 24px;
          background-color: #fd4848;
          border-radius: 50%;
        }

        nb-icon svg {
          fill: white;
        }
      }

    }
  }

  > nb-card {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  > nb-card > nb-card-header:hover {
    cursor: move;
  }

  nb-card-header .title {
    min-width: 400px;
    max-width: 500px;
    color: white;
  }


  nb-card-body {
    padding: 0;
    overflow: visible;
  }

}

.small-window {
  nb-card-header .title {
    min-width: 100px;
  }
}

.mat-mdc-dialog-container {
  border-radius: 22px !important;
}

//TABLE FILTER

.table-filter {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 22px;
    font-size: 12px;
    font-weight: normal;
  }

  .mat-button-toggle-checked {
    background-color: var(--primaryColor);
    color: #fff;
  }
}

.revision-filter {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 20px;
    font-size: 12px;
    font-weight: normal;
  }

  .mat-button-toggle-checked {
    background-color: var(--primaryColor);
    color: #fff;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--sportsSecondary);
}

.tripForm {
  .mat-horizontal-stepper-header-container {
    width: 60%;
    padding: 0.25em 1em;
    margin: 0.5em auto;
  }

  .mat-horizontal-stepper-header {
    height: 45px;
    width: 45px;
    justify-content: center;
    border-radius: 50%;
    padding: unset;
  }

  .mat-horizontal-content-container {
    padding: 0 12px 12px 12px;
  }

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover {
    background-color: var(--sportsSecondary);
  }

  .mat-horizontal-stepper-header .mat-step-icon {
    display: none;
  }

  .mat-step-label {
    min-width: unset;
  }

  .mat-step-header[aria-selected="true"] {
    background-color: var(--systemGray9);

    & div.mat-step-label.mat-step-label-active.mat-step-label-selected {
      color: var(--sportsSecondary);
    }

  }
}

form {
  font-size: 12px;

  input,
  input::placeholder {
    font-size: 12px;
    font-weight: normal;
  }

  input {
    width: 100%;
    padding: 0 5px;
    border-radius: 4px;
    border: 1px solid var(--systemGray5);
    height: 40px !important;

  }

  input:focus {
    border: 1px solid var(--sportsBlue);

  }

  //ng-select

  .ng-select.ng-select-single .ng-select-container {
    height: 40px !important;
  }

  .ng-select .ng-select-container {
    min-height: 40px;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    margin-top: 10px;
  }

  .ng-select .ng-clear-wrapper .ng-clear {
    margin-top: 10px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 140px;
  }

}

.ng-dropdown-panel .scroll-host::-webkit-scrollbar {
  display: none;
}

.ng-select .ng-select-container {
  font-size: 12px;
  border: 1px solid #e4e9f2;
  background-color: #f7f9fc;
}

.ng-dropdown-panel .scroll-host {
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-size: 12px;
}

//.ng-select .ng-arrow-wrapper {
//  display: none;
//}

.ng-select.invalid .ng-select-container {
  border-color: red;
}

form.reset .ng-select.ng-select-single .ng-select-container,
form.reset .ng-select.ng-select-multiple .ng-select-container {
  height: 34px !important;
}

.ng-select.role-permissions {

  .ng-select-container {
    max-height: calc(100vh - 322px);
    font-size: 14px;
    overflow: auto;
    align-items: flex-start;

    //.ng-clear-wrapper {
    //  display: none;
    //}
  }

  &.hide-overflow {
    .ng-select-container {
      overflow-x: hidden;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 120px;
  }

  .ng-dropdown-panel .scroll-host {
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-size: 10px;
    max-height: 120px !important;
  }
}

.ng-dropdown-panel .scroll-host {
  max-height: 150px !important;
}

//.ng-value-container {
//  margin-top: 5px;
//}


.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  color: #fff;
  background-color: var(--primaryColor);
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value:hover,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value :hover {
  color: black;
  background-color: #98f584;
}

.user {
  .ng-select.ng-select-disabled > .ng-select-container {
    background-color: var(--primaryColor);
    color: #fff;
  }

  .ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
    color: #000;
    background-color: #fff;
  }
}

// NB TAB

nb-tabset {
  --tabset-tab-text-line-height: .5rem;
  --tabset-tab-active-text-color: var(--text-primary-color);

  .tab.active {
    background-color: transparent;
  }
}

nb-tab {
  padding: 0 !important;
}

.crew-pax {

  nb-tabset .tab,
  nb-tabset .tab-link {
    height: 30px;
    align-items: center;
  }

  nb-tabset .tab:hover .tab-link {
    color: var(--primaryColor);
  }

  nb-tabset .tab:hover .tab-link::before {
    background-color: var(--primaryColor);
  }

  nb-tabset .tab.active .tab-link {
    background-color: transparent;
    color: var(--primaryColor);
  }

  nb-tabset .tab.active .tab-link::before {
    background-color: var(--primaryColor);
  }

}

nb-tabset .tab-link {
  justify-content: center;
  --tabset-tab-padding: .6em;
  --tabset-tab-text-font-size: 12px;
  --tabset-tab-text-transform: capitalize;
  --tabset-tab-active-text-color: var(--primaryColor);
  --tabset-tab-active-underline-color: var(--primaryColor);
  --tabset-tab-focus-text-color: var(--primaryColor);
  --tabset-tab-focus-underline-color: var(--primaryColor);
  --tabset-tab-hover-text-color: var(--primaryColor);
  --tabset-tab-hover-underline-color: var(--primaryColor);
}

.tabset .tab {
  flex-basis: 0;
  flex-grow: 1;
}


.custom-tab {
  --tabset-tab-active-text-color: var(--systemGary11);
}

.request-header {
  background-color: var(--sportsGray);

  nb-select.size-tiny .select-button.placeholder {
    font-size: 12px;
    color: var(--systemGray9);
  }

  nb-select.appearance-outline.size-tiny .select-button {
    opacity: 1;
  }
}

// MAT-MENU

.mat-mdc-menu-panel.nav-menu {
  width: 160px;
  height: 400px;
  overflow-x: hidden;
}

.mat-mdc-menu-panel.nav-menu .mat-mdc-menu-content {
  padding-top: 0;
}

.mat-mdc-menu-content {
  padding: 8px !important;
}

.mat-mdc-menu-item.mdc-list-item {
  border-radius: 4px;
}

.mailbox-status-filter .mat-mdc-menu-content,
.mailbox-menu .mat-mdc-menu-content {
  padding: 0;
  overflow: hidden;
}

.trip-menu {
  .mat-mdc-menu-item {
    min-height: 36px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: unset !important;
}


// MAT-EXPANSION PANEL

.remarks {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

.service,
.roles {
  .mat-expansion-panel-body {
    padding: .5em;
  }
}

.roles {
  .mat-expansion-indicator::after {
    color: #fff;
  }
}

.aircraft-trips {
  width: 100%;

  .mat-expansion-panel-body {
    padding-left: 1em;
    padding-top: .5em;
    padding-bottom: 0.5em;
  }

  .supplier-requests {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

// GANT

.gantt-calendar-overlay .primary-text,
.gantt-calendar-overlay .secondary-text {
  font-size: 12px;
}

.gantt .gantt-main-container::-webkit-scrollbar {
  height: 10px;
}

.gantt .gantt-main-container::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 0;
}

// NB-CHECKBOX
nb-checkbox.status-primary .native-input:disabled ~ .text {
  color: #000;
}

nb-checkbox.status-primary .native-input:disabled + .custom-checkbox .custom-checkbox-icon svg {
  fill: #fff;
}

nb-checkbox.status-primary .native-input:disabled:indeterminate + .custom-checkbox, nb-checkbox.status-primary .native-input:disabled:checked + .custom-checkbox {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}


// STATUS

.confirmed,
.active_credit {
  background-color: var(--sportsGreen) !important;
}

//.active,
//.monitored,
//.mdc-icon-button.monitored {
//  background-color: var(--sportsRed);
//}
//
//.suspended,
//.mdc-icon-button.suspended,
//.revision_cancelled,
//.charged_cancellation {
//  background-color: var(--sportsRed);
//}

.rejected {
  background-color: #000;
}

.postponed {
  background-color: var(--systemGray4);
}

.prospect,
.mdc-icon-button.prospect {
  background-color: var(--sportsBlue);
}

.inactive_credit,
.mdc-icon-button.inactive_credit {
  background-color: var(--sportsGray);
}

.pre_payment,
.mdc-icon-button.pre_payment {
  background-color: var(--systemGray4);
}

.credit_ops,
.mdc-icon-button.credit_ops {
  background-color: var(--primaryColor);
}

.credit_permit_and_fpl,
.mdc-icon-button.credit_permit_and_fpl {
  background-color: var(--sportsPurple);
}

.draft {
  background-color: var(--sportsGray);
}

.applied {
  background-color: var(--sportsBlue);
}

.revision_amd {
  background-color: var(--sportsPurple);
}

.reactivate_service {
  background-color: #F0F465;
}

.direct_cancelled {
  background-color: #BB7E5D;
}

.free_cancellation {
  background-color: #fd51a5;
}

.mat-mdc-icon-button.mat-mdc-button-base.delete {
  color: var(--sportsRed);
}

.invalid_request {
  background-color: var(--primaryColor);
  color: var(--primaryColor);
}

.not_required {
  background-color: var(--sportsSecondary);
}

//INPUT TYPE NUMBER

//Hide number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.appearance-ghost.status-primary {
  display: none;
}

.pac-container {
  z-index: 99999;
}

.mail-recipients .mat-form-field-infix {
  border-top: none;
}

.mail-recipients.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mail-recipients.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--sportsSecondary);
  bottom: 0.1em;
}

.mat-mdc-standard-chip .mdc-evolution-chip__icon--trailing {
  height: 12px !important;
  width: 12px !important;
  font-size: 12px !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: #fff;
}

.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  max-height: 200px;
  overflow-y: auto;
}

.table-wrapper {

  .gantt-elastic__header-title--text {
    font-weight: bold !important;
    padding-left: 0 !important;
  }

  .gantt-elastic__header-btn-recenter {
    background-color: var(--sportsPurple) !important;
    font-size: 12px !important;
  }

  .vue-slider-process {
    background-color: black;
  }

  .gantt-elastic__header-label {
    display: inline-flex;
    align-items: center;
    gap: 1em;
    color: #fff;
    padding: .5em 1em;
    background: var(--primaryColor);
    border-radius: 4px;
    font-size: 12px;
  }

  .gantt-elastic__header-label:nth-child(4),
  .gantt-elastic__header-label:nth-child(5),
  .gantt-elastic__header-task-list-switch--wrapper {
    display: none;
  }

  .gantt-elastic__chart-row-bar-polygon .gantt-elastic__chart-row-project-polygon {
    fill: #2bb074 !important;
    stroke: #2bb074 !important;
  }
}

.gantt-elastic__chart-row-bar-polygon .gantt-elastic__chart-row-project-polygon {
  fill: #2bb074 !important;
  stroke: #2bb074 !important;
}

// EMAIL LINK TYPES

.linked {
  background-color: #73C213;
}

.permit-approval {
  background-color: #EC7AB7;
}

.new-request {
  background-color: #FFC300;
}

.update-request {
  background-color: #85C1E9;
}

.service-applied {
  background-color: #676667;
}

.service-confirmation {
  background-color: #23C0A4;
}

.assigned {
  width: max-content;
  font-size: 0.7rem;
  background-color: var(--primaryColor);
}

.bg-gray {
  background-color: var(--systemGray7);
}


.tags {
  height: 20px;
}

table.mat-mdc-table .mdc-data-table__row.sector-expanded-row.selected {

  .mdc-data-table__cell {
    color: #fff;
    border-bottom: none;

    i {
      color: #fff;
    }

    i.text-danger {
      color: #000 !important;
    }
  }


  .thick-border {
    border: none;
  }

  background-color: var(--primaryColor);
  color: #fff;

  .mat-icon {
    color: #fff;
  }

}

table.mat-mdc-table .mdc-data-table__row.sector-expanded-row.selected {
  border-spacing: 0 1px !important;
}

tr.service-requests-row .mdc-data-table__cell:first-of-type {
  border-top-left-radius: 0;
  border-left: none;
}

tr.service-requests-row .mdc-data-table__cell:last-of-type {
  border-top-right-radius: 0;
  border-right: none;
}

table.mat-mdc-table .mdc-data-table__row.sector-expanded-row.selected .mdc-data-table__cell:first-of-type {
  border-bottom-left-radius: 0;
  border-left: none;
}

table.mat-mdc-table .mdc-data-table__row.sector-expanded-row.selected .mdc-data-table__cell:last-of-type {
  border-bottom-right-radius: 0;
  border-right: none;
}

.mdc-data-table__cell {
  overflow: visible !important;
}

.mdc-form-field > label {
  font-size: 12px;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 5px !important;
}

// MAT CHIP

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: var(--primaryColor);
  --mdc-chip-elevated-disabled-container-color: var(--primaryColor);
  --mdc-chip-label-text-color: #fff;
  --mdc-chip-disabled-label-text-color: #fff;
  --mdc-chip-with-icon-icon-color: #fff;
  --mdc-chip-with-icon-disabled-icon-color: #fff;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fff;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fff;
  --mdc-chip-with-icon-selected-icon-color: #fff;

  height: 18px !important;
  border-radius: 4px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mat-mdc-form-field-infix {
  min-height: 0;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 0;
  padding-bottom: 0;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: var(--primaryColor);

  .mdc-evolution-chip__text-label {
    color: #fff;
    font-size: .8em;
  }
}

.mat-mdc-standard-chip .mdc-evolution-chip__icon--trailing {
  height: 13px;
  width: 13px;
  font-size: 13px;
}

.mdc-text-field {
  padding-left: .2em !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  font-size: 12px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--sportsSecondary);
}

.list .mdc-evolution-chip-set {
  max-width: 610px;
}

// MAT ICON

.mat-icon.custom {
  height: 17px;
  font-size: 17px;
}

// MAT OPTION

.mat-mdc-option {
  min-height: 34px !important;
  font-size: 13px;
}

// MAT RADIO BUTTON
.single .mat-mdc-radio-button {
  .mdc-radio {
    padding: 0;
    margin-left: 0.3em;
  }
}


// NB RADIO

nb-radio.status-basic label:hover .native-input:checked:enabled ~ .inner-circle {
  --radio-basic-focus-inner-circle-color: var(--primaryColor);
  --radio-basic-hover-inner-circle-color: var(--primaryColor);
}


.first-form {
  .mat-mdc-radio-button .mdc-radio {
    scale: .8;
  }
}

// TEL INPUT

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: #e4e9f2;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #e4e9f2;
  background-color: #f7f9fc;
  color: #222b45;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

.iti.separate-dial-code input, .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
  padding-left: 78px !important;
}

ngx-intl-tel-input .iti {
  width: 100% !important;
}

.iti input,
.iti input[type='text'],
.iti input[type='tel'] {
  background-color: white !important;
  border-color: #dee2e6 !important;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b !important;
}
ngx-intl-tel-input.ng-valid.ng-touched input {
  border: 1px solid #198754 !important;
}

img.crew-logo {
  height: 13px;
}

.mat-expansion-panel {
  overflow: visible !important;
}

.mat-expansion-panel .mat-expansion-panel-header-title {
  color: white;
  align-items: center;
}

.mat-expansion-panel .mat-expansion-panel-header-description {
  font-weight: lighter;
  color: white;
}

.mat-expansion-panel-header {
  color: white;
  background-color: var(--primaryColor) !important;
}

.mat-expansion-panel:not(.mat-expanded) {
  border-radius: 8px !important;
  margin: 16px 0;
}

.mat-expansion-panel.mat-expanded .mat-expansion-panel-header {
  border-radius: 8px 8px 0 0 !important;
}

.custom-toast {
  border-radius: 5px;
  max-width: 40% !important;
  gap: 15px;
  padding: 16px !important;
  filter: drop-shadow(0 17px 11px rgba(0, 0, 0, 0.18));
  box-shadow: 0 8px 5px 1px rgba(0, 0, 0, 0.2588235294);
  min-width: 400px;
}

@media (min-width: 767px) {
  .custom-toast {
    max-width: 100% !important;
  }

}

.custom-toast svg {
  max-width: 40px;
}

.icon-container {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

}


nb-toast > div.icon-container.ng-star-inserted > nb-icon {
  font-size: 1.5rem !important;
}


.custom-toast .content-container {
  width: fit-content;
  max-width: 78%;
  color: #fff;
}

.custom-toast .title {
  font-weight: bold;
  font-size: 20px;
}

.custom-toast.status-success {
  border-left: 5px solid #fff;
  background: var(--primaryColor);
}

.custom-toast.status-success svg {
  fill: var(--primaryColor);
}

.custom-toast.status-danger {
  border-left: 5px solid #fff;
  background: red;
}

.custom-toast.status-danger svg {
  fill: red;
}

.custom-toast.status-info {
  border-left: 5px solid #fff;
  background: var(--systemGray7);
}

.custom-toast.status-info svg {
  fill: var(--systemGray7);
}

.custom-toast.status-warning {
  border-left: 5px solid #fff;
  background: #FFA500;
}

.custom-toast.status-warning svg {
  fill: #FFA500;
}


.scheduled {
  background-color: orange;
}

.processing {
  background-color: var(--primaryColor);
}

.refused {
  background-color: #420303;
  color: white;
}

.signed {
  background-color: cornflowerblue;
}

.completed {
  background-color: green;


}

.cancelled {
  background-color: red;
}

@media screen and (max-width:1200px) and (min-width:990px) {
  nb-window{
    transform: translate(-30%,-50%) !important;
  }  
}



//.cdk-overlay-container {
//  top: 0;
//  z-index: 4444;
//  display: flex;
//  position: fixed;
//  justify-content: center;
//  align-items: center;
//  width: 100%;
//  flex-direction: column;
//  gap: 3px;
//}

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";



