@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/default';


$nb-themes: nb-register-theme((
        color-info-default: var(--sportsGray),
        color-info-default-border: var(--sportsGray),
        color-primary-default: var(--sportsPrimary),
        text-primary-focus-color: var(--sportsPrimary),
        checkbox-basic-focus-checked-background-color: var(--sportsPrimary),
        checkbox-basic-focus-checked-border-color: var(--sportsPrimary),
        text-primary-hover-color: var(--sportsPrimary),
        checkbox-basic-hover-background-color: #ff69000f,
        checkbox-basic-hover-border-color: var(--sportsPrimary),
        checkbox-basic-hover-checked-background-color: var(--sportsPrimary),
        checkbox-basic-hover-checked-border-color: var(--sportsPrimary),
        checkbox-info-checked-background-color: var(--sportsGray),
        checkbox-info-focus-checked-background-color: var(--sportsGray),
        checkbox-info-focus-checked-border-color: var(--sportsGray),
        checkbox-info-hover-border-color: var(--sportsGray),
        checkbox-info-border-color: #8f9bb3,
        checkbox-info-checked-border-color: var(--sportsGray),
        checkbox-info-focus-border-color: var(--sportsGray),
        checkbox-info-hover-checked-background-color: var(--sportsGray),
        checkbox-info-hover-checked-border-color: var(--sportsGray),
        checkbox-info-hover-background-color: var(--sportsGray),
        radio-basic-focus-inner-circle-color: var(--sportsPrimary),
        radio-basic-hover-inner-circle-color: var(--sportsPrimary),
        radio-basic-hover-background-color: #ff69000f,
        radio-basic-hover-border-color: var(--sportsPrimary),
        radio-basic-focus-outer-circle-border-color: var(--sportsPrimary),
        radio-basic-focus-checked-border-color: var(--sportsPrimary),
        radio-basic-hover-checked-border-color: var(--sportsPrimary),
        calendar-cell-today-border-color: var(--sportsPrimary),
  // add your variables here like:
  // color-primary-100: #f2f6ff,
  // color-primary-200: #d9e4ff,
  // color-primary-300: #a6c1ff,
  // color-primary-400: #598bff,
  // color-primary-500: #3366ff,
  // color-primary-600: #274bdb,
  // color-primary-700: #1a34b8,
  // color-primary-800: #102694,
  // color-primary-900: #091c7a,
), default, default);

$sport-spot-palette: (
        50: #FFF3E0,
        100: #FFE0B2,
        200: #FFCC80,
        300: #FFB74D,
        400: #FFA726,
        500: #FF6900,
        600: #FB8C00,
        700: #F57C00,
        800: #EF6C00,
        900: #E65100,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
          // ... continues to 900
        )
);

@use '@angular/material' as mat;
@include mat.core();

$my-primary: mat.m2-define-palette($sport-spot-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
        ),
        density: 0,
));

@include mat.datepicker-theme($my-theme);
@include mat.progress-bar-theme($my-theme);
@include mat.badge-theme($my-theme);

.mat-datepicker-content {
  border: 1px solid #e4e9f2;
  box-shadow: none;
  margin-top: 4px;
}

.mat-datepicker-content .mat-calendar {
  width: 100% !important;
  height: 100% !important;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 0.1px;
}

.mat-calendar-body-selected {
  color: #fff;
}

.mat-drawer-transition .mat-drawer-content {
  min-height: 100vh !important;
}

.mat-calendar-controls {
  margin: 2% calc(4.7142857143% - 16px) !important;
}

.mat-mdc-button {
  font-size: 11.375px;
  font-weight: 700;
}

.mat-calendar-body-cell-content {
  top: 5%;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--sportsPrimary);
}

.mat-calendar-body-label, .mat-calendar-period-button {
  font-size: 12px;
  font-weight: 700;
  color: #8f9bb3;
}

.mat-calendar-table-header th {
  font-size: 12px;
  font-weight: 600;
  color: #8f9bb3;
}

.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-content .mat-calendar-next-button {
  color: #000;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 36px;
  height: 36px;
}

.mat-calendar-controls {
  .mat-mdc-icon-button:disabled {
    background: unset;
  }
}
